import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import Intro from '../intro';
import Button from '../button';
import Ok from '../ok';
import TextField from '../text-field';
import Lottie from 'lottie-react-web';

import auto from '../../animations/auto.json';
import postMessage from '../../utils/postMessage';
import style from './index.module.scss';

const modalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '0 2px 13px rgba(0, 0, 0, 0.2)',
		padding: '16px',
		maxHeight: '80vh',
		maxWidth: '600px',
		borderRadius: '8px',
		border: 'none'
	},
};

const FirstScreen = () => {
	const [showModal, setShowModal] = useState(false);
	const [showOk, setShowOk] = useState(false);
	const [pending, setPending] = useState(false);
	const [name, setName] = useState('');
	const [tel, setTel] = useState('');
	const [message, setMessage] = useState('');
	const el = useRef(null);

	return (
		<div ref={el} id="first" className={style.first}>
			{showOk && <Ok />}
			<Intro setShowModal={setShowModal} />
			<Modal
				portalClassName={style.portal}
				shouldReturnFocusAfterClose={true}
				appElement={el.current}
				isOpen={showModal}
				onRequestClose={() => setShowModal(false)}
				style={modalStyles}
				contentLabel="Центр автоподбора"
			>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
					<div className={style.modalHeader}>
						<h3>Центр автоподбора</h3>
					</div>
					<button className={style.modalClose} onClick={() => setShowModal(false)}>
						<i className="icon-cross" />
					</button>
				</div>
				<div className={style.formDescription}>
					<div className={style.formIcon}>
						<Lottie
							ariaLabel="Анимированный чеклист"
							ariaRole="img"
							speed={0.8}
							width="100%"
							options={{
								animationData: auto,
							}}
						/>
					</div>

					<p className={style.descriptionText}>
						Расскажите, какую услугу вы хотели бы приобрести? А также оставьте ваши пожелания.
					</p>
				</div>

				<form className={style.form}>
					<div className={style.inputContainer}>
						<TextField
							value={name}
							onChange={(e) => setName(e.target.value)}
							className={style.input}
							id="first-form-name"
							type="text"
							placeholder="Ваше имя*"
						/>
						<TextField
							value={tel}
							onChange={(e) => setTel(e.target.value)}
							className={style.input}
							id="first-form-tel"
							type="tel"
							placeholder="Ваш номер телефона*"
						/>
					</div>
					<textarea
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						className={style.area}
						id="first-form-message"
						rows={4}
						placeholder="Ваши пожелания"
					/>

					<Button
						disabled={pending}
						style={{ width: '100%', height: '52px' }}
						type="submit"
						onClick={(e) => postMessage(e, { uname: name, tel, message }, 3773, setPending, setShowOk, setShowModal)}
					>
						{pending ? 'Отправляем запрос...' : 'Заказать'}
					</Button>
				</form>
			</Modal>
			<button
				className={style.buttonArrow}
				aria-label="Далее"
				onClick={() => {
					const nav = document.querySelector('nav');
					const widget = document.querySelector('#first').getBoundingClientRect().bottom;
					window.scrollTo({
						top: widget - nav.offsetHeight,
						left: 0,
						behavior: 'smooth',
					});
				}}
			>
				<span className={style.arrow}/>
			</button>
		</div>
	);
};

export default FirstScreen;
