function insertScript(domNode, src) {
	let js,
		fjs = domNode.getElementsByTagName('script')[0];
	js = domNode.createElement('script');
	js.async = 1;
	js.src = src;
	fjs.parentNode.insertBefore(js, fjs);
}

export default insertScript;
