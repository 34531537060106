import React, { useState, useRef } from 'react';
import Button from '../button';
import Policy from './policy';
import Contacts from './contacts';
import style from './index.module.scss';
import contactInfo from '../../data/contacts.json';

const Footer = () => {
	const [showModal, setShowModal] = useState(false);
	const [showContacts, setShowContacts] = useState(false);
	const el = useRef(null);
	const contactsEl = useRef(null);

	function openModal() {
		setShowModal(true);
	}

	function closeModal() {
		setShowModal(false);
	}

	function openContacts() {
		setShowContacts(true);
	}

	function closeContacts() {
		setShowContacts(false);
	}

	return (
		<>
			<Policy closeModal={closeModal} modalRef={el.current} showModal={showModal} />
			<Contacts closeContacts={closeContacts} refContacts={contactsEl.current} showContacts={showContacts} />
			<div id="footer" className={style.footer}>
				<div className={style.logo}>
					<div className={style.logoImage} />
				</div>
				<div className={style.contacts}>
					<p>
						<b>Адрес:</b> {contactInfo.addr}, ТРК&nbsp;"Променад-3" (новый корпус, 1 этаж)
					</p>
					<p className={style.hours}>
						<b>Часы работы:</b> <br /> <span dangerouslySetInnerHTML={contactInfo.hours} />
					</p>
					<div className={style.tel}>
						<b>Телефоны:</b>
						<div className={style.numbers}>
							<a href={`tel:${contactInfo.tel}`}>{contactInfo['tel-format']}</a>
							<a href={`tel:${contactInfo['tel-cell']}`}>{contactInfo['tel-cell-format']}</a>
						</div>
					</div>
					<p className={style.mail}>
						<b>Почта:</b> <br />
						<a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
					</p>
				</div>

				<div className={style.socials}>
					<a title="Telegram" rel="noreferrer" target="_blank" href={`https://t.me/${contactInfo.telegram}`}>
						<i className="icon-telegram" />
					</a>
					<a title="WhatsApp" rel="noreferrer" target="_blank" href={`https://wa.me/${contactInfo['tel-cell']}`}>
						<i className="icon-whatsapp" />
					</a>
					<a title="Viber" rel="noreferrer" target="_blank" href={`https://viber.click/${contactInfo['tel-cell']}`}>
						<i className="icon-viber" />
					</a>
				</div>
				<div className={style.buttons}>
					<Button reset={true} className={style.policy} ref={el} onClick={openModal}>
						Политика конфиденциальности
					</Button>
					<Button reset={true} className={style.policy} ref={contactsEl} onClick={openContacts}>
						Реквизиты компании
					</Button>
				</div>
				<small>
					Все цены, опубликованные на сайте centr-ap.ru, не являются офертой. Актуальную информацию уточняйте у
					сотрудников компании.
				</small>
			</div>
			<script src={'//cdn.callibri.ru/callibri.js'} type="text/javascript" charSet="utf-8" defer={true} />
		</>
	);
};

export default Footer;
