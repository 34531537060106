import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ServiceCard from './service-card';
import style from './index.module.scss';

const Prices = () => {
	const data = useStaticQuery(graphql`
		query {
			allContentfulApService(sort: { fields: createdAt }) {
				edges {
					node {
						price
						steps
						name
						description {
							description
						}
						image {
							fluid {
								...GatsbyContentfulFluid
							}
						}
					}
				}
			}
		}
	`);

	return (
		<section id="prices" className={style.container}>
			<h2>Наши услуги</h2>
			<div className={style.services}>
				{data.allContentfulApService.edges.map((edge) => {
					return <ServiceCard key={edge.node.name} {...edge.node} />;
				})}
			</div>
		</section>
	);
};

export default Prices;
