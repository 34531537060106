import document from '../../images/how-we-do/document.png';
import monitoring from '../../images/how-we-do/monitoring.png';
import otchet from '../../images/how-we-do/otchet.png';
import pokupka from '../../images/how-we-do/pokupka.png';
import proverka from '../../images/how-we-do/proverka.png';
import vstrecha from '../../images/how-we-do/vstrecha.png';

export const pictures = {
	document,
	monitoring,
	otchet,
	pokupka,
	proverka,
	vstrecha,
};
