import { validatePhone } from './validation';

const postMessage = async (e, userInfo, r_u_id, pendingCb, okCb, finallyCb) => {
	const timeStamp = new Date().getTime();
	const { uname: u_name, tel: u_phone, message: u_note, description } = userInfo;
	e.preventDefault();
	if (u_name === '' || u_phone === '') {
		alert('Пожалуйста, заполните все поля!');
		return;
	}
	if (!validatePhone(u_phone)) {
		alert('Проверьте указанный номер телефона');
		return;
	}
	pendingCb(true);
	try {
		const res = await fetch('/.netlify/functions/lead-create', {
			headers: {
				'content-type': 'application/json',
			},
			method: 'POST',
			body: JSON.stringify({
				r_id_internal: timeStamp,
				r_u_id,
				source: 'centr-ap.ru',
				u_name,
				u_phone,
				u_note: description ? description + ': ' + u_note : u_note,
			}),
		});

		if (res.ok) {
			okCb(true);
			setTimeout(() => okCb(false), 2200);
		} else {
			alert('Произошла ошибка, сообщение не отправлено');
		}
	} catch (error) {
		alert('Ошибка отправки данных');
	} finally {
		finallyCb(false);
		pendingCb(false);
	}
};

export default postMessage;
