import React, { useState } from 'react';
import Image from 'gatsby-image';
import usePortal from 'react-cool-portal';
import style from './index.module.scss';
import isMobile from '../../utils/isMobile';
import Button from '../button';

const Check = ({ dangers, description, image }) => {
	const { Portal, hide, show } = usePortal({
		defaultShow: false,
	});
	const [portalHeader, setPortalHeader] = useState('');
	const [portalDescription, setPortalDescription] = useState('');

	const handleIconClick = (e) => {
		if (!isMobile()) return;
		setPortalDescription(e.target.parentNode.parentNode.nextElementSibling.textContent);
		setPortalHeader(e.target.parentNode.nextElementSibling.textContent);
		show();
	};

	return (
		<>
			<Portal>
				<div role="dialog" onClick={hide} onKeyPress={hide} className={style.portal}>
					<div className={style.portalContent}>
						<h5 className={style.portalHeader}>{portalHeader}</h5>
						<p className={style.portalDescription}>{portalDescription}</p>
					</div>
				</div>
			</Portal>
			<div className={style.description}>
				<Image className={style.image} fluid={image.fluid} />
				<p className={style.descriptionContent}>{description.description}</p>
			</div>
			<div className={style.dangers}>
				<h4 className={style.dangersHeader}>Какие могут быть проблемы?</h4>

				{dangers.map((danger) => (
					<div key={danger.label} className={style.danger}>
						<div className={style.dangerHeaderContainer}>
							<Button reset={true} onClick={handleIconClick} onKeyPress={handleIconClick}>
								<img width={46} height={46} src={danger.icon.fixed.src} alt={danger.label} className={style.icon} />
							</Button>
							<h5 className={style.dangerHeader}>{danger.label}</h5>
						</div>
						<p className={style.dangerDescription}>{danger.description}</p>
					</div>
				))}
			</div>
		</>
	);
};

export default Check;
