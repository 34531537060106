import React from 'react';
import smoothScrollTo from '../../utils/smoothScroll';
import Button from '../button';

import style from './index.module.scss';

const Menu = ({ setShowModal, nav }) => {
	function closeAndShow(selector) {
		setShowModal(false);
		smoothScrollTo(selector, nav);
	}

	return (
		<div className={style.menu}>
			<nav className={style.nav}>
				<Button reset={true} className={style.link} onClick={() => closeAndShow('#pros')}>
					Как мы работаем?
				</Button>

				<Button reset={true} className={style.link} onClick={() => closeAndShow('#checks')}>
					Что мы проверяем?
				</Button>

				<Button reset={true} className={style.link} onClick={() => closeAndShow('#experts')}>
					Наша команда
				</Button>

				<Button reset={true} className={style.link} onClick={() => closeAndShow('#special')}>
					Специальные предложения
				</Button>

				<Button reset={true} className={style.link} onClick={() => closeAndShow('#wizard')}>
					Пройти тест
				</Button>

				<Button reset={true} className={style.link} onClick={() => closeAndShow('#prices')}>
					Услуги
				</Button>

				<Button reset={true} className={style.link} onClick={() => closeAndShow('#office')}>
					Отзывы
				</Button>

				<Button reset={true} className={style.link} onClick={() => closeAndShow('#map')}>
					Как доехать
				</Button>

				<Button reset={true} className={style.link} onClick={() => closeAndShow('#footer')}>
					Контакты
				</Button>
			</nav>
		</div>
	);
};

export default Menu;
