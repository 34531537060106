import React from 'react';
import Form from '../form';

import style from './index.module.scss';

const Map = () => {
	return (
		<div id="map" className={style.map}>
			<iframe
				title="Yandex Map"
				src="https://yandex.ru/map-widget/v1/?um=constructor%3A1e9f58616e35cbf3007a64f9316bdd46ffd60ff4f44bb5019ed3e21fb80084c7&amp;source=constructor"
				width="100%"
				height="400"
				frameBorder="0"
			></iframe>
			<Form buttonText="Позвоните мне" label="Заказать звонок" id="map" className={style.form} />
		</div>
	);
};

export default Map;
