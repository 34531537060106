import React, { useState, useRef, useEffect } from 'react';
import Button from '../../components/button';
import Img from 'gatsby-image';
import Ok from '../ok';
import Modal from 'react-modal';
import postMessage from '../../utils/postMessage';
import isMobile from '../../utils/isMobile';
import TextField from '../text-field';
import customStyles from '../../utils/modalCustomStyles';
import Lottie from 'lottie-react-web';

import auto from '../../animations/auto.json';
import style from './index.module.scss';

const ServiceCard = ({ price, name, steps, image, description }) => {
	const [showModal, setShowModal] = useState(false);
	const [showOk, setShowOk] = useState(false);
	const [pending, setPending] = useState(false);

	const [uname, setUname] = useState('');
	const [tel, setTel] = useState('');
	const [message, setMessage] = useState('');

	const [showSteps, setShowSteps] = useState(true);

	const el = useRef(null);

	useEffect(() => {
		if (isMobile()) {
			setShowSteps(false);
		}
	}, []);

	return (
		<div className={style.serviceCard}>
			{showOk && <Ok />}
			<Modal
				shouldReturnFocusAfterClose={true}
				appElement={el.current}
				isOpen={showModal}
				onRequestClose={() => setShowModal(false)}
				style={customStyles}
				contentLabel={name}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
					<div className={style.modalHeader}>
						<h3>{name}</h3>
					</div>
					<button className={style.modalClose} onClick={() => setShowModal(false)}>
						<i className="icon-cross"></i>
					</button>
				</div>
				<div className={style.formDescription}>
					<div className={style.formIcon}>
						<Lottie
							ariaLabel="Анимированный автомобиль"
							ariaRole="img"
							speed={0.8}
							width="100%"
							options={{
								animationData: auto,
							}}
						/>
					</div>

					<p className={style.descriptionText}>
						Вы можете оставить чуть больше информации, чтобы мы могли заранее подготовить для вас наилучшее предложение.
					</p>
				</div>

				<form className={style.form}>
					<div className={style.inputContainer}>
						<TextField
							value={uname}
							onChange={(e) => setUname(e.target.value)}
							className={style.input}
							id="first-form-name"
							type="text"
							placeholder="Ваше имя*"
						/>
						<TextField
							value={tel}
							onChange={(e) => setTel(e.target.value)}
							className={style.input}
							id="first-form-tel"
							type="tel"
							placeholder="Ваш номер телефона*"
						/>
					</div>
					<textarea
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						className={style.area}
						id="first-form-message"
						type="text"
						rows={4}
						placeholder="Ваши пожелания"
					/>

					<Button
						disabled={pending}
						style={{ width: '100%', height: '52px' }}
						type="submit"
						onClick={(e) => {
							const info = { uname, tel, message, description: name };
							postMessage(e, info, 1, setPending, setShowOk, setShowModal);
						}}
					>
						{pending ? 'Отправляем запрос...' : 'Заказать'}
					</Button>
				</form>
			</Modal>

			<div className={style.image}>
				<Img alt={name} className={style.media} {...image} />
			</div>
			<h4
				data-sal="slide-bottom"
				data-sal-delay="100"
				data-sal-duration="300"
				data-sal-easing="ease-out"
				className={style.name}
			>
				{name}
			</h4>
			<p
				data-sal="slide-bottom"
				data-sal-delay="100"
				data-sal-duration="300"
				data-sal-easing="ease-out"
				className={style.description}
			>
				{description.description}
			</p>
			{isMobile && !showSteps && (
				<Button reset className={style.more} onClick={() => setShowSteps(true)}>
					Подробнее...
				</Button>
			)}
			{showSteps && <h5 className={style.subheading}>Что входит в услугу?</h5>}
			{showSteps && (
				<ol className={style.steps}>
					{steps.map((step, index) => (
						<li key={index}>{step}</li>
					))}
				</ol>
			)}
			<Button ref={el} className={style.button} onClick={() => setShowModal(true)}>
				Заказать
			</Button>
		</div>
	);
};

export default ServiceCard;
