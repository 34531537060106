import React, { useEffect } from 'react';
import scriptInsert from '../../utils/scriptInsert';

const Flamp = () => {
	useEffect(() => {
		scriptInsert(document, '//widget.flamp.ru/loader.js');
	}, []);

	return (
		<>
			<div id="flamp" />
			<a
				id="flamp-widget"
				className="flamp-widget"
				href="//kemerovo.flamp.ru/firm/centr_avtopodbora-70000001043574480"
				data-flamp-widget-type="responsive-new"
				data-flamp-widget-id="70000001043574480"
				data-flamp-widget-width="100%"
				data-flamp-widget-count="1"
			>
				Отзывы о нас на Флампе
			</a>
		</>
	);
};

export default Flamp;
