import React from 'react';
import style from './index.module.scss';
import { pictures } from './pictures';

const HowWeWork = () => {
	return (
		<div id="pros" className={style.pros}>
			<h2 data-sal="slide-bottom" data-sal-delay="100" data-sal-duration="500" data-sal-easing="ease-out">
				Профессиональный подбор автомобилей с пробегом в Кемерово
			</h2>
			<div className={style.text}>
				<div data-sal="slide-bottom" data-sal-delay="200" data-sal-duration="500" data-sal-easing="ease-out" className={style.benefits}>
					<h4 className={style.proHeader}>
						С нами надежно
					</h4>
					<h5>Гарантия технического состояния</h5>
					<p>Наша ответственность за техническое состояние автомобиля четко регламентирована и прописана в&nbsp;договоре</p>
					<h5>Контроль сделки с продавцом</h5>
					<p>Сопровождаем вас в процессе всей сделки с продавцом, обезопасим от&nbsp;обмана</p>
					<h5>Полная прозрачность нашей работы</h5>
					<p>Работаем честно, в ваших интересах. Вы можете контролировать каждый этап нашей работы, присутствовать на каждом осмотре</p>
					<h5>Гарантия отсутствия скрытого торга</h5>
					<p>Работаем в ваших интересах - никакого «торга за спиной» и&nbsp;«договорных рекомендаций»</p>
					<h5>Гарантия юридической чистоты</h5>
					<p>Автомобиль не в залоге, не угнан, на нем нет ограничений</p>
				</div>

				<div data-sal="slide-bottom" data-sal-delay="200" data-sal-duration="500" data-sal-easing="ease-out" className={style.benefits}>
					<h4 className={style.proHeader}>
						С нами выгодно
					</h4>
					<h5>Отсутствие крупных вложений после покупки</h5>
					<p>Вам не придется вкладывать деньги в «неожиданный» ремонт и замену агрегатов сразу после покупки</p>
					<h5>Экономия времени</h5>
					<p>Вы не тратите время на поиск автомобилей, обзвон продавцов, осмотры авто, подготовку и проверку документов</p>
					<h5>Защита от «автохлама»</h5>
					<p>Исключаем покупку восстановленных после крупных аварий автомобилей</p>
					<h5>Экономия стоимости наших услуг за счет торга</h5>
					<p>Мы аргументировано сбиваем стоимость, и в 85% случаев окупаем свои услуги, а вы экономите время и получаете гарантии</p>
					<h5>Лояльные условия оплаты (рассрочка и кредит)</h5>
					<p>Удобная оплата наших услуг, учитывая ваши возможности и&nbsp;потребности</p>
				</div>

			</div>
			<h2 data-sal="slide-bottom" data-sal-delay="100" data-sal-duration="500" data-sal-easing="ease-out">
				Как мы работаем?
			</h2>
			<h4 className={style.subheading}>
				Автоподбор – это просто и&nbsp;прозрачно<br></br> Всего 6 шагов и на выходе – автомобиль
				с&nbsp;гарантией&nbsp;и&nbsp;по лучшей стоимости
			</h4>
			<div className={style.prosContainer}>
				<div className={style.pro}>
					<h5
						data-sal="slide-bottom"
						data-sal-delay="100"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						1. Ваши пожелания
					</h5>
					<div className={style.proMedia}>
						<img height={75} width={75} src={pictures.vstrecha} alt="Встреча" />
					</div>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Выясняем какой конкретно автомобиль нужен клиенту или определяем ряд интересующих моделей
					</p>
				</div>

				<div className={style.pro}>
					<h5
						data-sal="slide-bottom"
						data-sal-delay="100"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						2. Мониторинг рынка
					</h5>
					<div className={style.proMedia}>
						<img height={75} width={75} src={pictures.monitoring} alt="Мониторинг рынка" />
					</div>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Просматриваем варианты, обзваниваем продавцов, "пробиваем" по платным базам автомобиль
					</p>
				</div>

				<div className={style.pro}>
					<h5
						data-sal="slide-bottom"
						data-sal-delay="100"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						3. Выездная проверка
					</h5>
					<div className={style.proMedia}>
						<img height={75} width={75} src={pictures.proverka} alt="Выездная проверка" />
					</div>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Проверяем автомобиль более чем по 95 параметрам. Проводим аргументированный торг с продавцом
					</p>
				</div>

				<div className={style.pro}>
					<h5
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						4. Отчет и рекомендации
					</h5>
					<div className={style.proMedia}>
						<img height={75} width={75} src={pictures.otchet} alt="Отчет и рекомендации" />
					</div>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Предоставляем отчет по проверенному автомобилю
					</p>
				</div>

				<div className={style.pro}>
					<h5
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						5. Покупка автомобиля
					</h5>
					<div className={style.proMedia}>
						<img height={75} width={75} src={pictures.pokupka} alt="Покупка автомобиля" />
					</div>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Проводим финальный осмотр автомобиля с клиентом. Контролируем проведение сделки
					</p>
				</div>

				<div className={style.pro}>
					<h5
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.proHeader}
					>
						6. Подготовка документов
					</h5>
					<div className={style.proMedia}>
						<img height={75} width={75} src={pictures.document} alt="Подготовка документов" />
					</div>
					<p
						className={style.proBody}
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
					>
						Подбираем оптимальный страховой полис ОСАГО.<br></br> Готовим документы для ГИБДД, помогаем оплатить
						госпошлины
					</p>
				</div>
			</div>
		</div>
	);
};

export default HowWeWork;
