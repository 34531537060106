import React, { useState } from 'react';
import Step from './step';
import Ok from '../ok';
import Button from '../button';
import TextField from '../text-field';
import { useStaticQuery, graphql } from 'gatsby';
import { validatePhone } from '../../utils/validation';

import style from './index.module.scss';

const Wizard = () => {
	const [step, setStep] = useState(0);
	const [answers, setAnswers] = useState([]);
	const [finish, setFinish] = useState(false);
	const data = useStaticQuery(graphql`
		query {
			allContentfulApQuestions(sort: { fields: order }) {
				nodes {
					question
					answers
				}
			}
		}
	`);
	const questions = data.allContentfulApQuestions.nodes;

	const [success, setSuccess] = useState(false);
	const [showOk, setShowOk] = useState(false);
	const [pending, setPending] = useState(false);
	const [uname, setUname] = useState('');
	const [tel, setTel] = useState('');
	const timeStamp = new Date().getTime();

	const postMessage = async (e, u_name, u_phone, u_note) => {
		e.preventDefault();
		if (u_name === '' || u_phone === '' || u_note === '') {
			alert('Пожалуйста, заполните все поля!');
			return;
		}
		if (!validatePhone(u_phone)) {
			alert('Проверьте указанный номер телефона');
			return;
		}
		setPending(true);
		try {
			const res = await fetch('/.netlify/functions/lead-create', {
				headers: {
					'content-type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify({
					r_id_internal: timeStamp,
					r_u_id: 3773,
					source: 'тест на сайте',
					u_name,
					u_phone,
					u_note,
				}),
			});

			if (res.ok) {
				setSuccess(true);
				setShowOk(true);
				setTimeout(() => setShowOk(false), 2200);
			} else {
				alert('Произошла ошибка, сообщение не отправлено');
			}
		} catch (error) {
			alert('Ошибка отправки данных');
		} finally {
			setPending(false);
		}
	};

	return (
		<div id="wizard" className={style.wizard}>
			<h2 className={style.heading}>Подобрать автомобиль</h2>
			<p className={style.subHeading}>
				Если вы пока еще сомневаетесь и не знаете какой авто приобрести, то вы в правильном месте! Пройдите этот быстрый
				и&nbsp;простой тест и&nbsp;получите рекомендации и конкретные варианты на рынке Кемерово от наших экспертов.
			</p>
			{showOk && <Ok />}
			{!finish && (
				<Step
					step={step}
					steps={questions.length}
					setAnswers={setAnswers}
					question={questions[step]}
					answers={answers}
				/>
			)}
			{finish && (
				<div className={style.wizardStep}>
					<form className={style.finishForm}>
						<h3>{!success ? 'Получить подборку' : 'Спасибо!'}</h3>
						<h4 style={{ maxWidth: '80%' }}>
							Наш специалист перезвонит вам сразу же, как только подберет самые подходящие варианты
						</h4>
						{!success && (
							<div className={style.finishFormContainer}>
								<TextField
									value={uname}
									onChange={(e) => setUname(e.target.value)}
									className={style.finishFormInput}
									id="wizard-form-name"
									type="text"
									label="Ваше имя"
									placeholder="Введите ваше имя"
								/>
								<TextField
									value={tel}
									onChange={(e) => setTel(e.target.value)}
									className={style.finishFormInput}
									id="wizard-form-tel"
									type="text"
									label="Ваш номер телефона"
									placeholder="+7 000 000 00 00"
								/>
							</div>
						)}
						{!success && (
							<Button
								disabled={success}
								className={style.finishFormButton}
								type="submit"
								onClick={(e) => postMessage(e, uname, tel, JSON.stringify(answers))}
							>
								{pending ? 'Отправляем...' : 'Отправить заявку'}
							</Button>
						)}
					</form>
				</div>
			)}
			<div className={style.buttons}>
				{step > 0 && !finish && (
					<Button className={style.backButton} onClick={() => setStep((prevStep) => prevStep - 1)}>
						{' '}
						Назад{' '}
					</Button>
				)}

				{step < questions.length - 1 && answers.length > step && (
					<Button onClick={() => setStep((prevStep) => prevStep + 1)}> Следующий</Button>
				)}

				{step === questions.length - 1 && answers.length > step && !finish && (
					<Button className={style.finishButton} onClick={() => setFinish(true)}>
						Готово
					</Button>
				)}

				{finish && (
					<Button className={style.backButton} onClick={() => setFinish(false)}>
						Назад
					</Button>
				)}
			</div>
		</div>
	);
};

export default Wizard;
