const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '0 2px 13px rgba(0, 0, 0, 0.2)',
		padding: '16px',
		maxHeight: '80vh',
		maxWidth: '600px',
		borderRadius: '8px',
		border: 'none',
	},
};

export default customStyles;
