import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import Ok from '../ok';
import Button from '../../components/button';
import TextField from '../../components/text-field';
import postMessage from '../../utils/postMessage';
import customStyles from '../../utils/modalCustomStyles';

import style from './index.module.scss';

const Expert = ({ info }) => {
	const { id, name, description, telephone, telegram, photo, uOnId } = info;
	const formattedTel = telephone.toString()[0] === '8' ? '7' + telephone.toString().substr(1) : telephone.toString();
	const telHref = `tel:${formattedTel}`;
	const telegramHref = `https://t.me/${telegram}`;
	const whatsAppHref = `https://wa.me/${formattedTel}`;

	const [showModal, setShowModal] = useState(false);
	const [showOk, setShowOk] = useState(false);
	const [pending, setPending] = useState(false);

	const [uname, setUname] = useState('');
	const [tel, setTel] = useState('');
	const [message, setMessage] = useState('');

	const el = useRef(null);

	function openModal() {
		setShowModal(true);
	}

	function closeModal() {
		setShowModal(false);
	}

	return (
		<>
			{showOk && <Ok />}
			<Modal
				shouldReturnFocusAfterClose={true}
				portalClassName={style.portal}
				appElement={el.current}
				isOpen={showModal}
				onRequestClose={closeModal}
				style={customStyles}
				contentLabel={name}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
					<div className={style.name}>
						<strong>{name}</strong>
					</div>
					<button className={style.modalClose} onClick={closeModal}>
						<i className="icon-cross"/>
					</button>
				</div>
				<div style={{ display: 'flex', marginBottom: '12px' }}>
					<img src={photo.fluid.src} alt={name} className={style.imageModal} />
					<div className={style.descriptionModal}>
						{description}
						<div className={style.telModal}>
							<span className={style.telegram}>
								<a onClick={(e) => e.stopPropagation()} href={telegramHref}>
									<i className="icon-telegram"/>
								</a>
							</span>
							<span className={style.whatsapp}>
								<a onClick={(e) => e.stopPropagation()} href={whatsAppHref}>
									<i className="icon-whatsapp"/>
								</a>
							</span>
							<a onClick={(e) => e.stopPropagation()} href={telHref}>
								{telephone}
							</a>
						</div>
					</div>
				</div>
				<form className={style.form}>
					<div className={style.inputContainer}>
						<TextField
							value={uname}
							onChange={(e) => setUname(e.target.value)}
							className={style.input}
							id={id + '-form-name'}
							type="text"
							placeholder="Ваше имя*"
						/>
						<TextField
							value={tel}
							onChange={(e) => setTel(e.target.value)}
							className={style.input}
							id={id + '-form-tel'}
							type="tel"
							placeholder="Ваш номер телефона*"
						/>
					</div>
					<textarea
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						className={style.area}
						id={id + '-form-message'}
						rows={4}
						placeholder="Ваш вопрос"
					/>

					<Button
						style={{ width: '100%', height: '40px' }}
						type="submit"
						onClick={(e) => {
							const info = { uname, tel, message };
							postMessage(e, info, uOnId, setPending, setShowOk, setShowModal);
						}}
					>
						{pending ? 'Отправляем...' : 'Узнать'}
					</Button>
				</form>
			</Modal>

			<div
				ref={el}
				role="button"
				className={style.expert}
				tabIndex={0}
				onKeyPress={(e) => {
					if (e.key === 'Enter') openModal();
				}}
				onClick={openModal}
			>
				<div className={style.photo}>
					<img src={photo.fluid.src} alt={name} className={style.media} />
				</div>
				<div className={style.info}>
					<div
						data-sal="slide-bottom"
						data-sal-delay="100"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.name}
					>
						{name}
					</div>
					<div
						data-sal="slide-bottom"
						data-sal-delay="300"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.description}
					>
						{description}
					</div>
					<div
						data-sal="slide-bottom"
						data-sal-delay="500"
						data-sal-duration="500"
						data-sal-easing="ease-out"
						className={style.tel}
					>
						<span className={style.telegram}>
							<a aria-label="Написать в телеграм" onClick={(e) => e.stopPropagation()} href={telegramHref}>
								<i className="icon-telegram"/>
							</a>
						</span>
						<span className={style.whatsapp}>
							<a aria-label="Написать в вотсап" onClick={(e) => e.stopPropagation()} href={whatsAppHref}>
								<i className="icon-whatsapp"/>
							</a>
						</span>
						<a aria-label="Позвонить эксперту" onClick={(e) => e.stopPropagation()} href={telHref}>
							{telephone}
						</a>
					</div>
					<Button
						onClick={(e) => {
							e.stopPropagation();
							openModal();
						}}
					>
						Задать вопрос
					</Button>
				</div>
			</div>
		</>
	);
};

export default Expert;
