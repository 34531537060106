import React, {useEffect} from 'react'
import ReactGA from 'react-ga';
import { YMInitializer } from 'react-yandex-metrika';

const Analytics = () => {
	useEffect(() => {
		ReactGA.initialize('G-7S5MB3QSC1');
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [])

	return <YMInitializer accounts={[76224256]} options={{webvisor: true}}/>
}

export default Analytics
