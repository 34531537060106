import picture1 from '../assets/images/intro/1.jpg';
import picture2 from '../assets/images/intro/2.jpg';
import picture3 from '../assets/images/intro/3.jpg';
import picture4 from '../assets/images/intro/4.jpg';
import picture5 from '../assets/images/intro/5.jpg';
import picture6 from '../assets/images/intro/6.jpg';

const content = [
	{
		title: 'Профессиональный подбор и проверка автомобилей с пробегом',
		description: `Планируете приобрести автомобиль на вторичном рынке, но боитесь купить «кота в мешке»? С нами бояться не придется. Проверяем самые важные характеристики авто. В итоге вы получаете автомобиль с гарантией.`,
		button: 'Обратиться к нам',
		image: picture1,
	},
	{
		title: 'Безопасность - приоритет',
		description: `Один из основных принципов нашей компании: «Подбираемый автомобиль в первую очередь должен быть безопасным для нового владельца». 
      Поэтому мы уделяем большое внимание параметрам безопасности во время проверки автомобилей `,
		button: 'Подобрать безопасный авто',
		image: picture5,
	},
	{
		title: 'Гарантия 2 месяца на техническое состояние ',
		description:
			'Наши эксперты – мастера своего дела. Поэтому мы с уверенностью даем гарантию на исправность подобранных автомобилей в течение 60 дней с момента покупки.',
		button: 'Хочу авто с гарантией',
		image: picture3,
	},
	{
		title: '85 из 100 подборов - бесплатны',
		description: 'В 85% случаев экономим нашим клиентам стоимость услуги автоподбора за счет аргументированного торга.',
		button: 'Хочу бесплатный подбор',
		image: picture4,
	},
	{
		title: 'Экономия Вашего времени',
		description: `Поиск подходящего автомобиля в интернете, звонок продавцу, проверка по базам, поездка на осмотр и… по новой. 
    Все это берем на себя. Клиент приезжает на финальный осмотр и на оформление сделки в нашем офисе.`,
		button: 'Хочу больше свободного времени',
		image: picture6,
	},
	{
		title: 'Контроль проведения сделки',
		description:
			'Сделка проводится в нашем офисе и по форме нашего договора купли-продажи, проверенного квалифицированным юристом, и который в полной мере учитывает интересы покупателя и продавца.',
		button: 'Безопасная сделка',
		image: picture2,
	},
];

export default content;
