import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useStaticQuery, graphql } from 'gatsby';
import Check from './check';
import style from './index.module.scss';

const Checks = () => {
	const data = useStaticQuery(graphql`
		query {
			allContentfulApChecks(sort: {fields: label, order: ASC}) {
				edges {
					node {
						label
						dangers {
							label
							description
							icon {
								fixed {
									src
								}
							}
						}
						image {
							fluid(quality: 90, maxWidth: 450, maxHeight: 450) {
								...GatsbyContentfulFluid
							}
						}
						description {
							description
						}
					}
				}
			}
		}
	`);

	return (
		<section id="checks">
			<h2>Что мы проверяем</h2>
			<Tabs className={style.tabs}>
				<TabList className={style.tablist}>
					{data.allContentfulApChecks.edges.map((edge) => {
						return (
							<Tab className={style.tab} key={edge.node.label}>
								{edge.node.label}
							</Tab>
						);
					})}
				</TabList>

				{data.allContentfulApChecks.edges.map((edge) => {
					return (
						<TabPanel className={style.tabPanel} key={edge.node.label}>
							<Check {...edge.node} />
						</TabPanel>
					);
				})}
			</Tabs>
		</section>
	);
};

export default Checks;
