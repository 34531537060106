import React from 'react';
import Slider from 'react-animated-slider';
import Button from '../button';
import style from './index.module.scss';
import content from '../../data/carousel';
import 'react-animated-slider/build/horizontal.css';
import './slider-animation.scss';
import './slider.scss';

const Intro = ({ setShowModal }) => {
	return (
		<Slider touchDisabled={true} autoplay={3000} className="slider-wrapper">
			{content.map((item, index) => (
				<div
					key={index}
					className="slider-content"
					style={{ background: `url('${item.image}') no-repeat center center` }}
				>
					<div className="inner">
						<h1>{item.title}</h1>
						<p>{item.description}</p>
						<Button className={style.button} onClick={() => setShowModal(true)}>
							{item.button}
						</Button>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default Intro;
