import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CarouselProvider, Image, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import debounce from '../../utils/debounce';
import 'pure-react-carousel/dist/react-carousel.es.css';
import style from './index.module.scss';

const slideWidth = 640;

const Office = () => {
	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { relativeDirectory: { eq: "office" } }, sort: { fields: name, order: DESC }) {
				edges {
					node {
						childImageSharp {
							fluid(quality: 80, maxWidth: 960) {
								srcWebp
								srcSetWebp
								sizes
							}
						}
					}
				}
			}
		}
	`);

	const getSlidesCount = () => {
		const slides = Math.floor(window.innerWidth / slideWidth);
		if (slides < 1) return 1;
		if (slides > 2) return 2;
		return slides;
	};

	const [slides, setSlides] = useState(1);

	useEffect(() => {
		setSlides(getSlidesCount());
		window.addEventListener('resize', () => debounce(setSlides(getSlidesCount())));
		return () => {
			window.removeEventListener('resize', () => debounce(setSlides(getSlidesCount())));
		};
	}, []);

	return (
		<div id="office" className={style.container}>
			<h2>Приходите к&nbsp;нам</h2>
			<p className={style.text}>
				Отличное расположение в центре города, просторная парковка, отдельный вход. И конечно же, современный и уютный
				офис, в&nbsp;котором подбор вашего нового автомобиля пройдет с комфортом за&nbsp;ароматной чашечкой кофе.
			</p>
			<CarouselProvider
				className={style.carousel}
				dragEnabled={false}
				visibleSlides={slides}
				naturalSlideWidth={2500}
				naturalSlideHeight={1667}
				totalSlides={data.allFile.edges.length}
			>
				<Slider className={style.slider}>
					{data.allFile.edges.map((edge, index) => {
						return (
							<Slide key={index} index={index}>
								<Image
									alt="Наш офис"
									style={{ margin: '0 auto' }}
									srcSet={edge.node.childImageSharp.fluid.srcSetWebp}
									src={edge.node.childImageSharp.fluid.srcWebp}
									size={edge.node.childImageSharp.fluid.sizes}
								/>
							</Slide>
						);
					})}
				</Slider>
				<ButtonBack className={style.btnBack} />
				<ButtonNext className={style.btnNext} />
			</CarouselProvider>
		</div>
	);
};

export default Office;
