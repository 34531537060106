import React, { useState } from 'react';
import SEO from '../components/seo';
import Header from '../components/header';
import FirstScreen from '../components/first-screen';
import HowWeWork from '../components/how';
import Experts from '../components/experts';
import Footer from '../components/footer';
import Office from '../components/office';
import Map from '../components/map';
import Flamp from '../components/flamp';
import Wizard from '../components/wizard';
import Menu from '../components/menu';
import Prices from '../components/prices';
import Checks from '../components/checks';
import Analytics from '../components/analitycs';

const IndexPage = () => {
	const [showModal, setShowModal] = useState(false);
	const [nav, setNav] = useState(null);

	return (
		<div style={{ overflowX: 'hidden', pointerEvents: 'all' }}>
			<Analytics />
			<Header showModal={showModal} setNav={setNav} setShowModal={setShowModal} />
			{showModal && <Menu nav={nav} setShowModal={setShowModal} />}
			<FirstScreen />
			<main style={{ overflowX: 'hidden', padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
				<SEO />
				<HowWeWork />
				<Experts />
				<Checks />
				<Prices />
				<Wizard />
				<Office />
				<Flamp />
			</main>
			<Map />
			<Footer />
		</div>
	);
};

export default IndexPage;
